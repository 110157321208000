<template>
	<div class="open-account-bank">
		<div class="step-box">
			<div class="step-item">
				<van-image width="20" :src="resourceURL + 'images/step01_h.png'"/>
				<p>填写资料</p>
			</div>
		
			<div class="step-item">
				<img :src="resourceURL + 'images/step_line_h.png'" alt="">
			</div>
		
			<div class="step-item">
				<van-image width="20" :src="resourceURL + 'images/step02_h.png'"/>
				<p>身份验证</p>
			</div>
			<div class="step-item">
				<img :src="resourceURL + 'images/step_line.png'" alt="">
			</div>
			<div class="step-item">
				<van-image width="20" :src="resourceURL + 'images/step03.png'"/>
				<p>提交审核</p>
			</div>
		</div>
		
		<van-form @submit="nextStep()">
			<h4 class="h4_title">银行卡上传</h4>
			
			<div style="background-color: #FFFFFF;text-align: center;padding: 20px;">
				<van-image
					width="150"
					height="150"
					:src="bank_front_url"
					@click="call_bank_front_pic"
				/>
			</div>

			<h4 class="h4_title">银行账户信息</h4>

			<van-field
				readonly
				clickable
				label="开户银行"
				:value="indexBank > -1 ? banks[indexBank].text : ''"
				placeholder="请选择"
				@click="showBank = true"
				:rules="rules.bank_id"
				required
			/>

			<van-popup v-model="showBank" round position="bottom">
				<van-picker
					title="开户银行"
					show-toolbar
					:columns="banks"
					:default-index="indexBank"
					@cancel="showBank = false"
					@confirm="onConfirmBank"
				/>
			</van-popup>

			<van-field 
				v-if="indexBank > -1 && formItem.bank_id == 0"
				v-model="formItem.bank_name" 
				label="其他银行" 
				placeholder="请输入"
				:rules="rules.bank_name"
				required
			/>

			<van-field 
				v-model="formItem.bank_branch" 
				label="所属支行" 
				placeholder="请以「XX省XX市XX支行」格式填写"
				:rules="rules.bank_branch"
				required
			/>
			
			<van-field 
				v-model="formItem.bank_number" 
				label="银行卡号" 
				placeholder="请输入"
				:rules="rules.bank_number"
				required
			/>
			
			<div class="common_block">
				<van-button type="warning" block native-type="submit">下一步</van-button>
			</div>
		</van-form>

	</div>
</template>

<script>
	export default {
		name: 'account_bank',
		data() {
			return {
				formItem: {
					bank_id: "",
					bank_name: "",
					bank_branch: "",
					bank_number: "",
					bank_front_pic: "",
				},

				rules: {
					bank_id: [
						{
							required: true,
							message: '请选择开户银行',
							trigger: 'onBlur'
						}
					],

					bank_name: [
						{
							required: true,
							message: '请输入开户银行',
							trigger: 'onBlur'
						}
					],

					bank_branch: [
						{
							required: true,
							message: '请输入所属支行',
							trigger: 'onBlur'
						}
					],
					
					bank_number: [
						{
							required: true,
							message: '请输入银行卡号',
							trigger: 'onBlur'
						}
					],
				},

				token: "",
				
				showBank: false,

				resourceURL: this.$axios.defaults.resourceURL,
				
				bank_front_url: this.$axios.defaults.resourceURL + "images/icon_bank_add.png",
				
				indexBank: -1,
				banks: [],

				disabledSubmit: true,
				
			}
		},
		mounted() {
			
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.token = this.$route.query.token
				
				// 将方法绑定到window下面，提供给外部调用
				window.set_bank_front_pic = this.set_bank_front_pic
				
				this.init()
			}
		},
		methods: {
			init(){
				this.$axios({
					method: 'post',
					url: 'account_open/banks'
				}).then ((res) => {
					if (res.success) {

						this.banks = res.data

						this.banks.push({
							bank_id: 0,
							text: '其他'
						})
						
					}else{
						this.$toast.fail(res.error_text)
					}
				})
				
				this.$axios({
					method: 'post',
					url: 'account_open/info',
					data: {
						token: this.token
					}
				}).then ((res) => {
					if (res.success) {

						if (res.data.status == 2) {
							//	待审核
							this.$router.replace('/account/success')
						} else if (res.data.status == 3) {
							//	不通过
							this.$router.replace('/account/fail?token=' + this.token)
						} else {
							
							var bank_data = res.data.bank_data		
							
							if (bank_data.length != 0) {
								this.formItem.bank_id = bank_data.bank_id ? bank_data.bank_id : ""
								this.formItem.bank_name = bank_data.bank_name ? bank_data.bank_name : ""
								this.formItem.bank_branch = bank_data.bank_branch ? bank_data.bank_branch : ""
								this.formItem.bank_number = bank_data.bank_number ? bank_data.bank_number : ""
								this.formItem.bank_front_pic = bank_data.bank_front_pic ? bank_data.bank_front_pic : ""
								
								if (bank_data.bank_front_url) {
									this.bank_front_url = bank_data.bank_front_url
								}

								this.banks.forEach((item, key) => {
									if(item.bank_id == bank_data.bank_id){
										this.indexBank = key
									}								
								})
							}

						}

					}else{
						this.$toast.fail(res.error_text)
					}
				})

				
			},

			call_bank_front_pic(){
                if (window.JsObject != null) {
                    window.JsObject.callBankPic();
                }

                if (window.webkit != null) {
                    window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"camera": "bank_front_pic"});
                }
			},
			
			/*   
             * 参数path：
             *     上传图片在服务器的路径
             */
            set_bank_front_pic(path){

				this.$axios({
					method: 'post',
					url: 'account_open/resize',
					data: {
						img: path
					}
				}).then ((res) => {
					if (res.success) {
						this.bank_front_url = res.thumb
						this.formItem.bank_front_pic = path
					}else{
						this.$toast.fail('拍摄失败，请重试！')
					}
				})
			},
			onConfirmBank(value, index) {
				if (value) {
					this.indexBank = index

					this.formItem.bank_id = this.banks[index].bank_id
				
					// 选择银行时，清除输入的银行名称
					this.formItem.bank_name = ''
				}
				
				this.showBank = false
			},
			nextStep(){

				if(this.formItem.bank_front_pic == '') {
					this.$toast.fail('请上传银行卡')
					return false
				}
				
				this.$axios({
					method: 'post',
					url: 'account_open/alter',
					data: {
						token: this.token,
						bank_data: JSON.stringify(this.formItem)
					}
				}).then ((res) => {
					if (res.success) {
						if (res.data.hold_lock == 2) {
							this.$router.push('/account/success')
						} else {
							this.$router.replace('/account/lock?token=' + this.token)
						}
					}else{
						this.$toast.fail(res.error_text)
					}
				})
				
			}
		},
	}
</script>